@font-face {
  font-family: "Switzer";
  src: url("../../public/fonts/Switzer-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("../../public/fonts/Switzer-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("../../public/fonts/Switzer-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "InterMedium";
  src: url("../../public/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

