@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/fonts.css';

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Switzer';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root,
#root > div,
#root > div > div {
  height: 100%;
}

#root > div > div {
  display: flex;
  min-height: 100vh;
  height: auto;
  flex-direction: column;
}

#App-content {
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
