// AgeGate.scss

$primary-color: #007bff;
$background-color: #f4f4f4;
$card-background-color: white;
$card-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

.age-gate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 228px;
    gap: 48px;
    flex: 1 0 0;
}

.age-gate {
  background-color: $card-background-color;
  padding: 20px;
  border-radius: 8px;
  box-shadow: $card-shadow;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
    h2{
      color: var(--content-content-inverse, #FFF);
      text-align: center;
      /* typography/h2 */
      font-family: "Switzer";
      font-size: 90px;
      font-style: normal;
      font-weight: 800;
      line-height: 90%; /* 81px */
      letter-spacing: -0.9px;
      margin-top: 48px;
        }
    label {
      margin-top: 24px;
      margin-bottom: 0px;
      font-family: "Switzer";
      font-size: 18px;
    }
   & .button-container{
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-top: 48px;
      & .btn {
        font-family: "Switzer";
        font-size: 18px;
        font-weight: 700;
        line-height: 110%; /* 19.8px */
        display: flex;
        padding: 16px 48px;
        justify-content: center;
        align-items: center;
        gap: '16px';
        border-radius: 240px;
        min-width: '127px';
        box-shadow: 0px 2px 4px 0px rgba(107, 114, 128, 0.12), 0px 3px 5px 0px rgba(107, 114, 128, 0.15);
      }
    
   }

  &.fade-out {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s, transform 0.5s;
  }
}
.links {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-top: 70px;
    a {
  font-family: "Switzer";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 17.6px */
    }
}

.brand-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.date-inputs {
  display: flex;
  justify-content: space-between;

  input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

button {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.error-message {
  color: red;
  margin-top: 10px;
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  // width: 100vw;
  // height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Set alpha value for transparency */
  z-index: 9998; /* Below the age gate container */
  transition: opacity 0.5s;
  opacity: 0;
}